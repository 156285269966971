/*-----------------------------------------------
 共通JS
-----------------------------------------------*/

//スマホメニュー
$(".toggle").click(function() {
  $("nav").toggleClass("on");
});
//グローバルナビのカラー
$(function() {
  var url = location.pathname;
  var url_dir_length = url.split('/').length;
  var url_file_name = url.split('/')[url_dir_length - 1];
  var url_dir_name = url.split('/')[url_dir_length - 2];
  var navi = $(".menu a");

  navi.each(function(){
      var href = $(this).attr("href");
      var href_dir_length= $(this).attr("href").split('/').length;
      if (url_dir_length == href_dir_length) {
          if (url_dir_name == '') {// case if url is not contains directry.
              var href_dir_name = href.split('/')[href_dir_length - 2];
              var href_page_name = href.split('/')[href_dir_length - 1];
              if ((url_file_name == href_page_name) || (url_file_name=='' && href_page_name=='index.html')) {
                  $(this).addClass("active");
                  return false;
              }
          } else {
              var href_dir_name = href.split('/')[href_dir_length - 2];
              if (url_dir_name == href_dir_name) {
                  $(this).addClass("active");
                  return false;
              }
          }
      }
  });
});
